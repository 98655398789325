<template>
  <div class="login-container">
    <el-form
      class="login-form"
      :model="formLabelAlign"
      size="medium"
      ref="ruleForm"
      :rules="rules"
    >
      <div class="login-logo">
        <img src="@/assets/img/navlogo2.png" />
        <!-- iYa 爱押 -->
      </div>
      <el-form-item class="login-formItem" prop="userName">
        <el-input
          v-model.trim="formLabelAlign.userName"
          placeholder="请输入帐号"
          @keyup.enter.native="submitForm('ruleForm')"
        >
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
      </el-form-item>

      <el-form-item class="login-formItem" prop="password">
        <el-input
          type="password"
          v-model.trim="formLabelAlign.password"
          placeholder="请输入密码"
          @keyup.enter.native="submitForm('ruleForm')"
        >
          <i slot="prefix" class="el-input__icon el-icon-lock"></i>
        </el-input>
      </el-form-item>
      <!-- <el-checkbox
        v-model="rememberPassword"
        style="color:#CCCCCC"
      >记住密码</el-checkbox> -->
      <div class="errorBox">
        {{ loginErrorMessage }}
      </div>
      <el-button
        :loading="loading"
        class="login-button"
        type="primary"
        @click="submitForm('ruleForm')"
      >
        {{ loadingText }}
      </el-button>
    </el-form>
  </div>
</template>

<script>
// import canvas from '@/js/canvas';
import ApiApp from '@/api/app';

export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      loadingText: '登录',
      loginErrorMessage: '',
      formLabelAlign: {
        userName: '',
        password: '',
      },
      rememberPassword: false,
      rules: {
        userName: [
          {
            required: true,
            message: '请输入帐号',
            trigger: 'change',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'change',
          },
        ],
      },
    };
  },
  watch: {},
  beforeCreate() {},
  created() {},
  mounted() {
    // canvas.startCanvas();
  },
  destroyed() {
    // canvas.hideCanvas();
  },
  methods: {
    submitForm(formName) {
      // eslint-disable-next-line consistent-return
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          this.loadingText = '登录中';
          ApiApp.login({
            userName: this.formLabelAlign.userName,
            password: this.$md5(this.formLabelAlign.password),
          })
            .then(res => {
              console.log(res);
              localStorage.setItem('token', res.result.accessToken);
              this.$store.dispatch('login/GetStaffInfo', {
                ...res.result,
                userName: this.formLabelAlign.userName,
              });
              this.$router.push('/').catch(err => {
                console.log(err);
              });
              this.loginErrorMessage = '';
            })
            .catch(err => {
              this.loginErrorMessage = err.message;
              this.loading = false;
              this.loadingText = '登录';
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/element/element-variables.scss';
.login-container {
  background: #23262e;
  background-size: 100%;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;
  /deep/ label.el-form-item__label {
    color: #ffffff;
  }
  /deep/ .el-input {
    input {
      background: #ffffff;
      color: #333333;
      height: 42px;
      line-height: 42px;
    }
  }
  .el-input--medium .el-input__icon {
    height: 42px;
    line-height: 42px;
    font-size: 20px;
    color: $--color-primary;
  }
  /deep/ .el-checkbox__inner {
    background: transparent;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background: $--color-primary;
  }
  .login-logo {
    text-align: center;
    height: 80px;
    color: $--color-primary;
    font-size: 30px;
    line-height: 80px;
    margin-bottom: 30px;
    img{
      width: 170px;
    }
  }
  .login-form {
    width: 400px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #ffffff;
    background-size: 100%;
    padding: 30px;
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 rgba(255, 255, 255, 0.3);
  }
  .login-button {
    width: 100%;
    margin-top: 12px;
  }
  .el-form-item {
    margin-bottom: 30px;
  }
  .errorBox {
    color: #f56c6c;
    font-size: 14px;
    text-align: center;
    height: 20px;
    line-height: 20px;
  }
}
</style>
